import { ref, onMounted, computed } from "@vue/composition-api";
import { useRouter } from '@core/utils/utils'
import useAppConfig from '@core/app-config/useAppConfig'
import i18n from "@/libs/i18n"
import useNotifications from '@/composables/useNotifications'
import useCommon from "@/views/organization/useCommon"
import realmConnection from '@/views/habit/realm'

export default function useAssistanceView() {
    
    const { route } = useRouter()
    const { showErrorMessage } = useNotifications()
    const { formatIsoDate } = useCommon()
    const { skin } = useAppConfig()
    const { getItem, getItemsWithAggregate, ObjectId } = realmConnection()
    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
    const defaultLanguage = JSON.parse(localStorage.getItem("clientData") || '{}').default_language
    const { assistanceId } = route.value.params
    const assistance = ref({})
    const isLoading = ref(true)
    const skinValue = computed(() => skin.value)
    const event = ref({})
    const improvement = ref({})

    const getAssistanceData = async () => {
      isLoading.value = true

      try {
        const query = { _id: ObjectId(assistanceId) }
        const item = await getItem({ collection: 'assistance', query })
        if (!item) throw new Error('Item not found')
  
        assistance.value = item
        if (assistance.value.creationDate) assistance.value.creationDate = formatIsoDate(assistance.value.creationDate)
        assistance.value.actionPlan = parseActionPlanData(assistance.value.actionPlan)
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.assistance_fetch_error'))
      } finally {
        isLoading.value = false
      }
    }

    const parseActionPlanData = (actionPlan) => {
       // Divide the actionPlan into fragments according to numbering
      return actionPlan?.split(/\d+\D/).slice(1).map(fragment => {
        // Split the fragment into title and text if there is ":"
        const separatorIndex = fragment.indexOf(":")
        if (separatorIndex !== -1) {
          const title = fragment.substring(0, separatorIndex).trim()
          const text = fragment.substring(separatorIndex + 1).trim()
          return { title, text };
        } else {
          return { text: fragment.trim() }
        }
      })
    }

    const getEventData = async () => {
      try {
        const query = { assistance: ObjectId(assistanceId) }

        const pipeline = [
          { $match: query },
          { $lookup: { from: 'worker', localField: 'participants', foreignField: '_id', as: 'participants', pipeline: [ { $project: { name: 1 } } ] } },
          { $project: { start: 1, end: 1, participants: 1 }}
        ]
  
        const items = await getItemsWithAggregate({ collection: 'event', pipeline })
        if (!items?.[0]) throw new Error('Item not found')

        const eventData = items[0]

        if (eventData.start) {
          const formattedStartDate = eventData.start.toLocaleString(
            `${defaultLanguage || 'en'}-US`,
            {
              day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
            })
          eventData.start = formattedStartDate;
        }

        if (eventData.end) {
          const formattedEndDate = eventData.end.toLocaleString(
            `${defaultLanguage || 'en'}-US`,
            {
              day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
            })
          eventData.end = formattedEndDate
        }

        if (eventData.participants?.length) {
          eventData.participants = eventData.participants.map(e => e.name).join(" / ")
        }

        event.value = eventData
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_fetch_error'))
      }
    }

    const getImprovementData = async () => {
      try {
        const query = { assistance: ObjectId(assistanceId) }
  
        const pipeline = [
          { $match: query },
          {
            $lookup: {
              from: 'worker',
              localField: 'assignee',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } } ],
              as: 'assignee'
            }
          },
          { $addFields: { assignee: { $arrayElemAt: ["$assignee", 0] } } },
        ]
      
        const items = await getItemsWithAggregate({ collection: 'improvement', pipeline })
        if (!items?.[0]) throw new Error('Item not found')

        const improvementData = items[0]

        if (improvementData.dueDate) {
          improvementData.dueDate = improvementData.dueDate.toLocaleDateString(`${defaultLanguage || 'en'}-US`)
        }

        if (improvementData.tags?.length) {
          improvementData.tags = improvementData.tags.map(e => i18n.t(`domain.${e}`)).join(" / ")
        }

        improvement.value = improvementData
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_fetch_error'))
      }
    }

    const printInvoice = () => {
      window.print()
    }

    onMounted(() => {
      getAssistanceData()
      getEventData()
      getImprovementData()
    })

    return {
      getAssistanceData,
      assistance,
      isLoading,
      printInvoice,
      skinValue,
      commitmentFunctionality,
      event,
      improvement,
    }
}