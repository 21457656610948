<template >
  <section class="invoice-preview-wrapper">
    
    <!-- No data found -->
    <div v-if="isLoading" class="text-center">
      <loading :active="true"  :is-full-page="false" :color="colors.primary"/>
    </div>

    <!-- Body -->
    <b-row v-else class="invoice-preview">
      <span class="mb-2 ml-1">{{ $t('message.tableHeader.creationDate') }}: {{ assistance.creationDate }}</span>
      <b-row class="mb-2 mx-25">
        <b-col cols="12" md="4" class="mb-1">

          <!-- Problem Context -->
          <b-card no-body class="invoice-preview-card h-100">
            <b-card-body class="invoice-padding pb-2">
              <div class="bg-primary rounded mb-2">
                <h3 class="text-center text-white invoice-logo py-25">
                  {{ $t('message.problem_context') }}
                </h3>
              </div>
              <p class="card-text mb-25">
                {{ assistance.problem }}
              </p>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Actual State -->
        <b-col cols="12" md="4" class="mb-1">
          <b-card no-body class="invoice-preview-card h-100">
            <b-card-body class="invoice-padding pb-2">
              <div class="bg-primary rounded mb-2">
                <h3 class="text-center text-white invoice-logo py-25">
                  {{ $t('message.actual_state') }}
                </h3>
              </div>
              <p class="card-text mb-25">
                {{ assistance.actualState }}
              </p>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Future State -->
        <b-col cols="12" md="4" class="mb-1">
          <b-card no-body class="invoice-preview-card h-100">
            <b-card-body class="invoice-padding pb-2">
              <div class="bg-primary rounded mb-2">
                <h3 class="text-center text-white invoice-logo py-25">
                  {{ $t('message.future_state') }}
                </h3>
              </div>
              <p class="card-text mb-25">
                {{ assistance.futureState  }}
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- Root Cause Analysis -->
      <b-card no-body class="invoice-preview-card mx-2">
        <b-card-body class="invoice-padding">
          <div class="bg-primary rounded mb-2">
            <h3 class="text-center text-white invoice-logo py-25">
              {{ $t('message.root_cause_analysis') }}
            </h3>
          </div>
          <b-row class="ml-25 justify-content-between">
            <b-col cols="12" md="2" class="mb-1">
              <h5 style="font-weight: 600;">1. {{ assistance.whyQuestion1 }}</h5>
              <p class="card-text">{{ assistance.whyAnswer1 }}</p>
            </b-col>
            <b-col cols="12" md="2" class="mb-1">
              <h5 style="font-weight: 600;">2. {{ assistance.whyQuestion2 }}</h5>
              <p class="card-text">{{ assistance.whyAnswer2 }}</p>
            </b-col>
            <b-col cols="12" md="2" class="mb-1">
              <h5 style="font-weight: 600;">3. {{ assistance.whyQuestion3 }}</h5>
              <p class="card-text">{{ assistance.whyAnswer3 }}</p>
            </b-col>
            <b-col cols="12" md="2" class="mb-1">
              <h5 style="font-weight: 600;">4. {{ assistance.whyQuestion4 }}</h5>
              <p class="card-text">{{ assistance.whyAnswer4 }}</p>
            </b-col>
            <b-col cols="12" md="2">
              <h5 style="font-weight: 600;">5. {{ assistance.whyQuestion5 }}</h5>
              <p class="card-text">{{ assistance.whyAnswer5 }}</p>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-body class="ml-2 invoice-padding pb-2">
          <h5 style="font-weight: 600;">{{ $t('message.root_cause') }}</h5>
          <div class="rounded mb-2 pl-2 pr-2" :style="{ 'background-color': skinValue === 'light' ? '#f0f0f0' : '#404b60' }">
            <h5 class="invoice-logo py-1" style="line-height: 1.5">{{ assistance.rootCause }}</h5>
          </div>
        </b-card-body>
      </b-card>

      <!-- Action Plan -->
      <b-card no-body class="invoice-preview-card mx-2 w-100">
        <b-card-body class="invoice-padding">
          <div class="bg-primary rounded mb-2">
            <h3 class="text-center text-white invoice-logo py-25">
              {{ $t('message.action_plan') }}
            </h3>
          </div>
          <div class="row mr-25">
            <div class="col-lg p-0" v-for="(item, index) in assistance.actionPlan" :key="index">
              <b-card class="mb-1 ml-1" :style="{ 'background-color': skinValue === 'light' ? '#f0f0f0' : '#404b60'}">
                <b-card-body class="d-flex flex-column p-0">
                  <div class="d-flex flex-row align-items-center justify-content-between w-100">
                    <div class="d-flex align-items-center" style="width: 70px; height: 70px;">
                      <img
                        src="@/assets/images/elements/arrow.png"
                        alt="arrow"
                        class="img-fluid rounded rounded justify-content-start"
                      >
                    </div>
                    <div class="d-flex align-items-center justify-content-center rounded-circle bg-primary" style="width: 50px; height: 50px;">
                      <img
                        :src="require('@/assets/images/elements/' + index + '.png')"
                        :alt="`${index}`"
                        class="img-fluid rounded rounded w-50 justify-content-end"
                      >
                    </div>
                  </div>
                  <h5 v-if="item.title" style="font-weight: 600; margin-top: 1rem">{{ item.title }}:</h5>
                  <p>{{ item.text }}</p>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </b-card-body>
      </b-card>

      <!-- Follow Up -->
      <b-card no-body class="invoice-preview-card mx-2 w-100">
        <b-card-body>
          <div class="bg-primary rounded">
            <h3 class="text-center text-white invoice-logo py-25">
              {{ $t('message.follow_up') }}
            </h3>
          </div>
          <b-card-body class="d-flex flex-column">
            <div class="table-responsive">
              <table class="table d-md-table">
                <h6 class="text-primary" colspan="2">{{ $t('label.EventFollowUp') }}</h6>
                <tr :style="{ 'background-color': skinValue === 'light' ? '#f0f0f0' : '#404b60' }">
                  <th style="width: 33.33%">{{ $t('label.start_date') }}</th>
                  <th style="width: 33.33%">{{ $t('label.end_date') }}</th>
                  <th style="width: 33.33%">{{ $t('label.participants') }}</th>
                </tr>
                <tr>
                  <td style="width: 33.33%">{{ event.start }} hs.</td>
                  <td style="width: 33.33%">{{ event.end }} hs.</td>
                  <td style="width: 33.33%">{{ event.participants }}</td>
                </tr>
              </table>
            </div>
            <div class="table-responsive mt-2">
              <table class="table d-md-table">
                <h6 class="text-primary" colspan="2">{{ commitmentFunctionality ? $t('Commitment') : $t('message.improvement') }}</h6>
                <tr :style="{ 'background-color': skinValue === 'light' ? '#f0f0f0' : '#404b60' }">
                  <th style="width: 33.33%">{{ $t('label.assignee') }}</th>
                  <th style="width: 33.33%">{{ $t('label.due_date') }}</th>
                  <th style="width: 33.33%">Tags:</th>
                </tr>
                <tr>
                  <td style="width: 33.33%">{{ improvement.assignee && improvement.assignee.name }}</td>
                  <td style="width: 33.33%">{{ improvement.dueDate }}</td>
                  <td style="width: 33.33%">{{ improvement.tags }}</td>
                </tr>
              </table>
            </div>
          </b-card-body>
          <b-button
            class="ml-2"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="secondary"
            @click="printInvoice"
          >
            {{ $t('label.print') }}
          </b-button>
        </b-card-body>
      </b-card>
    </b-row>
    <!-- <assistance-sidebar-send />
    <assistance-sidebar-add /> -->
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AssistanceSidebarSend from './AssistanceSidebarSend.vue'
import AssistanceSidebarAdd from './AssistanceSidebarAdd.vue'
import useAssistanceView from './useAssistanceView.js'
import VueMarkdown  from 'vue-markdown-v2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'

// Solo si usamos Flowise + Mermaid
// import mermaid from 'mermaid'
// import VueMermaidString from 'vue-mermaid-string'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    VueMarkdown,
    Loading,
    // VueMermaidString,

    AssistanceSidebarAdd,
    AssistanceSidebarSend,
  },
  setup() {
    const { assistance, isLoading, printInvoice, skinValue, actionPlanMermaid, rootCauseAnalysisMermaid, commitmentFunctionality, event, improvement } = useAssistanceView()
    // mermaid.initialize({ startOnLoad: true })

    return {
      assistance,
      isLoading,
      printInvoice,
      skinValue,
      commitmentFunctionality,
      event,
      improvement,
      colors
      // actionPlanMermaid,
      // rootCauseAnalysisMermaid
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.bg {
  background-color: red !important
}
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
